import Bugsnag, { NotifiableError } from "@bugsnag/js";
import { getFeDeviceDetails } from "./device";
import { getUtmParams } from ".";
import { getAuth, signOut } from "firebase/auth";
import { setErrorToast, setErrorToastMessage } from "@/lib/features/deviceSlice";
import { store } from "@/lib/store";

type IPayloadStats = {
    eventName: string,
    userId: string,
    chatSessionId?: string,
    deviceType?: string,
    personaId?: string,
    extraData?: Record<string, string | number | boolean | string[]>,
    source?: string,
    age?: string,
    gender?: string,
    characterIds?: Array<string>
    category?: string
}

type IError = {
    status: string;
};

type IAnalyzePayload = {
    userId: string,
    chatSessionId: string,
    characterId: string,
    requestId: string,
    base64Image: string
};

const handleSignOut = () => {
    const dispatch = store.dispatch;
    const auth = getAuth();
    dispatch(setErrorToastMessage('Your session has expired, please login again'));
    setTimeout(() => {
        dispatch(setErrorToast({ showErrorToast: true }));
    }, 100);
    signOut(auth)
        .then(() => {
            window.location.reload();
        })
        .catch(() => {
            dispatch(setErrorToast({ showErrorToast: true }));
        });
};

let envValue: string | null = null;

const fetchEnvVariables = async () => {
    const res = await fetch("/sup-ai/api/env");
    const data = await res.json();
    return data?.envValue;
};

export const reportBugsnagError = (e: NotifiableError, id?: string) => {
    Bugsnag.notify(e, (event) => {
        event.addMetadata('userData', {
            uaSessionId: window.sessionStorage?.getItem('fe_uaSessionId'),
            uaId: window.localStorage?.getItem('fe_uaId'),
            userId: id,
        });
        if (typeof window !== 'undefined') {
            event.addMetadata('clientInfo', {
                userAgent: navigator?.userAgent,
                visibilityState: document?.visibilityState,
                onLine: navigator?.onLine,
                utm: getUtmParams(),
                url: window?.location?.href,
                os: getFeDeviceDetails().feOsName,
                device: getFeDeviceDetails().feDeviceType,
            });
        }
    });
};

export const fetchChatdata = async (userId: string, characterId: string, forceNewSession?: boolean) => {
    const uaId = window.localStorage?.getItem('fe_uaId');
    const uaSessionId = window.sessionStorage?.getItem('fe_uaSessionId');
    const wsupUaId = window.localStorage?.getItem('wsup_uaId');
    const wsupUaSessionId = window.sessionStorage?.getItem('wsup_uaSessionId');
    const feOsName = getFeDeviceDetails().feOsName;
    const feDeviceType = getFeDeviceDetails().feDeviceType;
    const utmCampaign = sessionStorage?.getItem('utmCampaign');
    const utmSource = sessionStorage?.getItem('utmSource');
    const utmMedium = sessionStorage?.getItem('utmMedium');
    const pageReferrer = sessionStorage?.getItem('pageReferrer') || document.referrer;
    const auth = getAuth();
    const { currentUser } = auth;
    if (!currentUser) {
        handleSignOut();
        return;
    }
    let accessToken: string | undefined = undefined;
    try {
        accessToken = await currentUser?.getIdToken();
    } catch (e) {
        Bugsnag.notify(
            new Error(`Token Generation Failed: ${e}`) as NotifiableError,
            (event) => {
                event.addMetadata('userData', {
                    uaSessionId: window.sessionStorage?.getItem('fe_uaSessionId'),
                    uaId: window.localStorage?.getItem('fe_uaId'),
                    userId: userId,
                });
                if (typeof window !== 'undefined') {
                    event.addMetadata('clientInfo', {
                        userAgent: navigator?.userAgent,
                        visibilityState: document?.visibilityState,
                        onLine: navigator?.onLine,
                        utm: getUtmParams(),
                        url: window?.location?.href,
                        os: getFeDeviceDetails().feOsName,
                        device: getFeDeviceDetails().feDeviceType,
                    });
                }
            }
        );
        if ((e as any).code === 'auth/user-token-expired') {
            handleSignOut();
            return;
        } else {
            accessToken = await currentUser?.getIdToken();
        }
    }
    try {
        if (!envValue || typeof envValue !== 'string') {
            envValue = await fetchEnvVariables();
        }
        const chatDetails = await fetch(`${envValue}/ai/api/char/v1/session`, {
            method: 'POST',
            headers: {
                client: 'nowgg',
                'Authorization': `WsupV1 ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, pageReferrer, uaId, uaSessionId, wsupUaId, wsupUaSessionId, feOsName, feDeviceType, characterId, forceNewSession, utmCampaign, utmSource, utmMedium })
        });
        const response = await chatDetails.json();
        return response;
    } catch (e) {
        if ((e as IError).status !== 'FailureUnauthorized') {
            reportBugsnagError(new Error(`Session API Fail: ${e}`) as NotifiableError, userId);
        }
        return { status: 'Fail' }
    }
};


export const login = async (firebaseToken: string | undefined, id: string) => {
    const pageReferrer = sessionStorage?.getItem('pageReferrer') || document.referrer;
    try {
        if (!envValue || typeof envValue !== 'string') {
            envValue = await fetchEnvVariables();
        }
        const response = await fetch(`${envValue}/ai/api/auth/v1/login`, {
            method: 'POST',
            headers: {
                client: 'nowgg',
                'Authorization': `WsupV1 ${firebaseToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pageReferrer })
        });
        const res: { status: string } = await response.json();
        if (res.status === 'Success') {
            return 'Success';
        } else {
            reportBugsnagError(new Error(`Login API Fail: ${res.status}`) as NotifiableError, id);
            return 'Fail';
        }
    } catch (e) {
        if ((e as IError).status !== 'FailureUnauthorized') {
            reportBugsnagError(new Error(`Login API Fail: ${e}`) as NotifiableError, id);
        }
        return 'Fail';
    }
};

export const reportEvent = async (payload: IPayloadStats, type = 'stats') => {
    const uaId = window.localStorage?.getItem('fe_uaId');
    const uaSessionId = window.sessionStorage?.getItem('fe_uaSessionId');
    const wsupUaId = window.localStorage?.getItem('wsup_uaId');
    const wsupUaSessionId = window.sessionStorage?.getItem('wsup_uaSessionId');
    const feOsName = getFeDeviceDetails().feOsName;
    const feDeviceType = getFeDeviceDetails().feDeviceType;
    const utmCampaign = sessionStorage?.getItem('utmCampaign');
    const utmSource = sessionStorage?.getItem('utmSource');
    const utmMedium = sessionStorage?.getItem('utmMedium');
    if (!envValue || typeof envValue !== 'string') {
        envValue = await fetchEnvVariables();
    }
    const url = type === 'stats' ? `${envValue}/ai/api/stats/v1/reportEvent` : `${envValue}/ai/api/stats/v1/reportImpressionEvent`;
    const auth = getAuth();
    const pageReferrer = sessionStorage?.getItem('pageReferrer') || document.referrer;
    const { currentUser } = auth;
    if (!currentUser) {
        handleSignOut();
        return;
    }
    let accessToken: string | undefined = undefined;
    try {
        accessToken = await currentUser?.getIdToken();
    } catch (e) {
        Bugsnag.notify(
            new Error(`Token Generation Failed: ${e}`) as NotifiableError,
            (event) => {
                event.addMetadata('userData', {
                    uaSessionId: window.sessionStorage?.getItem('fe_uaSessionId'),
                    uaId: window.localStorage?.getItem('fe_uaId'),
                });
                if (typeof window !== 'undefined') {
                    event.addMetadata('clientInfo', {
                        userAgent: navigator?.userAgent,
                        visibilityState: document?.visibilityState,
                        onLine: navigator?.onLine,
                        utm: getUtmParams(),
                        url: window?.location?.href,
                        os: getFeDeviceDetails().feOsName,
                        device: getFeDeviceDetails().feDeviceType,
                    });
                }
            }
        );
        if ((e as any).code === 'auth/user-token-expired') {
            handleSignOut();
            return;
        } else {
            accessToken = await currentUser?.getIdToken();
        }
    }
    try {
        await fetch(url, {
            method: 'POST',
            headers: {
                client: 'nowgg',
                'Authorization': `WsupV1 ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...payload, uaId, pageReferrer, wsupUaId, wsupUaSessionId, uaSessionId, feOsName, feDeviceType, client: 'nowgg', utmCampaign, utmSource, utmMedium })
        })
    }
    catch (e) {

    }
};

export const analyzeImage = async (payload: IAnalyzePayload, id: string) => {
    const uaId = window.localStorage?.getItem('fe_uaId');
    const uaSessionId = window.sessionStorage?.getItem('fe_uaSessionId');
    const wsupUaId = window.localStorage?.getItem('wsup_uaId');
    const wsupUaSessionId = window.sessionStorage?.getItem('wsup_uaSessionId');
    const feOsName = getFeDeviceDetails().feOsName;
    const feDeviceType = getFeDeviceDetails().feDeviceType;
    const utmCampaign = sessionStorage?.getItem('utmCampaign');
    const utmSource = sessionStorage?.getItem('utmSource');
    const utmMedium = sessionStorage?.getItem('utmMedium');
    if (!envValue || typeof envValue !== 'string') {
        envValue = await fetchEnvVariables();
    }
    const url = `${envValue}/ai/api/char/v1/analyze`;
    const pageReferrer = sessionStorage?.getItem('pageReferrer') || document.referrer;
    const auth = getAuth();
    const { currentUser } = auth;
    if (!currentUser) {
        handleSignOut();
        return;
    }
    let accessToken: string | undefined = undefined;
    try {
        accessToken = await currentUser?.getIdToken();
    } catch (e) {
        Bugsnag.notify(
            new Error(`Token Generation Failed: ${e}`) as NotifiableError,
            (event) => {
                event.addMetadata('userData', {
                    uaSessionId: window.sessionStorage?.getItem('fe_uaSessionId'),
                    uaId: window.localStorage?.getItem('fe_uaId'),
                    userId: id,
                });
                if (typeof window !== 'undefined') {
                    event.addMetadata('clientInfo', {
                        userAgent: navigator?.userAgent,
                        visibilityState: document?.visibilityState,
                        onLine: navigator?.onLine,
                        utm: getUtmParams(),
                        url: window?.location?.href,
                        os: getFeDeviceDetails().feOsName,
                        device: getFeDeviceDetails().feDeviceType,
                    });
                }
            }
        );
        if ((e as any).code === 'auth/user-token-expired') {
            handleSignOut();
            return;
        } else {
            accessToken = await currentUser?.getIdToken();
        }
    }
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                client: 'nowgg',
                'Authorization': `WsupV1 ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...payload, uaId, pageReferrer, wsupUaId, wsupUaSessionId, uaSessionId, feOsName, feDeviceType, utmCampaign, utmSource, utmMedium })
        });
        const res = await response.json();
        return res;
    } catch (e) {
        if ((e as IError).status !== 'FailureUnauthorized') {
            reportBugsnagError(new Error(`Analyze API Fail: ${e}`) as NotifiableError, id);
        }
        return 'Fail';
    }
}
