import { CharactersData } from "@/app/dashboard/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CategoryData = {
    data: CharactersData[],
    cursor: string | null,
    hasNextPage: boolean,
    isDataFetched?: boolean
}
export interface ICharData {
    charactersData: Record<string, CategoryData>,
    selectedCharacter: CharactersData | null,
    charIds: Record<string, Record<string, boolean>>,
    categories: Array<string>,
    featuredCharacters: CharactersData[]
}

const initialState: ICharData = {
    charactersData: {},
    selectedCharacter: null,
    charIds: {},
    categories: [],
    featuredCharacters: []
};

export const charSlice = createSlice({
    name: "charData",
    initialState,
    reducers: {
        setCharData: (state, action: PayloadAction<{ category: string, data: CategoryData }>) => {
            const selectedCategory = action.payload.category;
            if (state.charactersData[selectedCategory] && state.charactersData[selectedCategory].data) {
                state.charactersData[selectedCategory] = { data: [...state.charactersData[selectedCategory].data, ...action.payload.data.data], cursor: action.payload.data.cursor, hasNextPage: action.payload.data.hasNextPage };
            } else {
                state.charactersData[selectedCategory] = { data: action.payload.data.data, cursor: action.payload.data.cursor, hasNextPage: action.payload.data.hasNextPage, isDataFetched: true }
                state.charactersData[selectedCategory].data = [...action.payload.data.data];
            }
        },
        setSelectedCharacter: (state, action: PayloadAction<CharactersData | null>) => {
            state.selectedCharacter = action.payload
        },
        setCharIds: (state, action: PayloadAction<{ charIds: Record<string, boolean>, selectedCategory: string }>) => {
            state.charIds[action.payload.selectedCategory] = { ...state.charIds[action.payload.selectedCategory], ...action.payload.charIds }
        },
        setCategories: (state, action: PayloadAction<Array<string>>) => {
            state.categories = action.payload;
        },
        setFeaturedCharacters: (state, action: PayloadAction<CharactersData[]>) => {
            state.featuredCharacters = action.payload;
        },
    },
});

export const { setCharData, setSelectedCharacter, setCharIds, setCategories, setFeaturedCharacters } = charSlice.actions;
export const charReducer = charSlice.reducer;