import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ClaimedCards = {
    cardId: string;
    characterId: string;
    imageUrl: string;
    characterName: string;
    chatSessionId: string;
};

export type Characters = {
    characterId: string;
    name: string;
    characterImageUrl: string;
    chatSessionCount: number;
    rank?: number;
    isPrivate?: boolean;
}

export interface IUserData {
    userData: {
        name?: string,
        avatar?: string,
        id?: string,
        accessToken?: string,
        gender?: string,
        isNewUser?: boolean,
        authUserId?: string,
        isSignedIn?: boolean,
        showVerificationScreen?: boolean;
        description?: string;
        email?: string;
        age?: string;
        userVerified?: boolean;
        likedData?: Record<string, Array<string>>;
        videoQuotaLeft?: number,
        postInProgress?: boolean,
        postUploaded?: boolean,
        publishedFeedId?: string,
        publishedPostTitle?: string,
        userPostFeedId?: string | null;
        cardsDataFetched?: boolean;
        emailUpdated?: boolean;
        userCountryCode?: string;
    };
    claimedCards: ClaimedCards[];
    scrollPosition: number;
    showUserDetails: boolean;
    redirectUrl: string;
    characters: Characters[];
    charactersFetched: boolean;
    isNSFWEnabled: boolean;
    searchValue: string;
    createPost: boolean;
    postCharacterId: string;
    sharePost: {
        showShareModal: boolean;
        postTitle: string;
        postId: string;
        postAuthor: string;
    };
    loginVisible: boolean;
    showDeleteConfirmation: string;
    showLoginModal: boolean;
    loginModalSource: string;
    showLoader: boolean;
}

const initialState: IUserData = {
    userData: {
        name: '',
        avatar: '',
        id: '',
        accessToken: '',
        gender: '',
        isNewUser: false,
        authUserId: '',
        isSignedIn: false,
        showVerificationScreen: false,
        description: '',
        email: '',
        age: '',
        userVerified: true,
        likedData: {},
        videoQuotaLeft: 10,
        postInProgress: false,
        postUploaded: false,
        publishedFeedId: '',
        publishedPostTitle: '',
        userPostFeedId: null,
        cardsDataFetched: false,
        emailUpdated: false,
        userCountryCode: ''
    },
    claimedCards: [],
    scrollPosition: 0,
    showUserDetails: false,
    redirectUrl: '',
    characters: [],
    charactersFetched: false,
    isNSFWEnabled: false,
    searchValue: '',
    createPost: false,
    postCharacterId: '',
    sharePost: {
        showShareModal: false,
        postTitle: '',
        postId: '',
        postAuthor: ''
    },
    loginVisible: false,
    showDeleteConfirmation: '',
    showLoginModal: false,
    loginModalSource: '',
    showLoader: false
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<typeof initialState.userData>) => {
            state.userData = { ...state.userData, ...action.payload };
        },
        updateUserData: (state, action: PayloadAction<typeof initialState.userData>) => {
            state.userData = { ...state.userData, ...action.payload };
        },
        setClaimedCards: (state, action: PayloadAction<typeof initialState.claimedCards>) => {
            state.claimedCards = action.payload
        },
        updateClaimedCards: (state, action: PayloadAction<ClaimedCards>) => {
            state.claimedCards = [...state.claimedCards, action.payload]
        },
        updateScrollPosition: (state, action: PayloadAction<number>) => {
            state.scrollPosition = action.payload;
        },
        setUserDetailsScreen: (state, action: PayloadAction<boolean>) => {
            state.showUserDetails = action.payload;
        },
        setRedirectUrl: (state, action: PayloadAction<string>) => {
            state.redirectUrl = action.payload;
        },
        setCharacters: (state, action: PayloadAction<Characters[]>) => {
            state.characters = action.payload;
            state.charactersFetched = true;
        },
        updateCharacters: (state, action: PayloadAction<Characters>) => {
            state.characters = [...state.characters, action.payload]
        },
        updateNSFWValue: (state, action: PayloadAction<boolean>) => {
            state.isNSFWEnabled = action.payload
        },
        updateLikedData: (state, action: PayloadAction<Record<string, Array<string>>>) => {
            state.userData.likedData = action.payload
        },
        updateLikedFeeds: (state, action: PayloadAction<Record<string, string>>) => {
            if (action.payload.action === 'like' && state.userData.likedData) {
                if (state.userData.likedData.likedFeedIds) {
                    state.userData.likedData.likedFeedIds.push(action.payload.feedId);
                } else {
                    state.userData.likedData.likedFeedIds = [action.payload.feedId];
                }
            } else if (action.payload.action === 'dislike' && state.userData.likedData) {
                if (state.userData.likedData.likedFeedIds) {
                    state.userData.likedData.likedFeedIds = state.userData.likedData.likedFeedIds.filter((id) => id !== action.payload.feedId);
                }
            }
        },
        updateLikedComments: (state, action: PayloadAction<Record<string, string>>) => {
            if (action.payload.action === 'like' && state.userData.likedData) {
                if (state.userData.likedData.likedCommentIds) {
                    state.userData.likedData.likedCommentIds.push(action.payload.commentId);
                } else {
                    state.userData.likedData.likedCommentIds = [action.payload.commentId];
                }
            } else if (action.payload.action === 'dislike' && state.userData.likedData) {
                if (state.userData.likedData.likedCommentIds) {
                    state.userData.likedData.likedCommentIds = state.userData.likedData.likedCommentIds.filter((id) => id !== action.payload.commentId);
                }
            }
        },
        setSearchValue: (state, action: PayloadAction<string>) => {
            state.searchValue = action.payload
        },
        showCreatePost: (state, action: PayloadAction<boolean>) => {
            state.createPost = action.payload
        },
        setPostCharacterId: (state, action: PayloadAction<string>) => {
            state.postCharacterId = action.payload;
        },
        setSharePost: (state, action: PayloadAction<typeof initialState.sharePost>) => {
            state.sharePost = { ...state.sharePost, ...action.payload };
        },
        setLoginVisible: (state, action: PayloadAction<boolean>) => {
            state.loginVisible = action.payload;
        },
        setShowDeleteConfirmation: (state, action: PayloadAction<string>) => {
            state.showDeleteConfirmation = action.payload;
        },
        setShowLoginModal: (state, action: PayloadAction<{ showLoginModal: boolean; loginModalSource: string }>) => {
            state.showLoginModal = action.payload.showLoginModal;
            state.loginModalSource = action.payload.loginModalSource;
        },
        setLoadingState: (state, action: PayloadAction<boolean>) => {
            state.showLoader = action.payload;
        }
    },
});

export const { setSearchValue, setLoadingState, setPostCharacterId, showCreatePost, setUserData, setRedirectUrl, updateUserData, setCharacters, updateCharacters, setClaimedCards, updateClaimedCards, updateScrollPosition, setUserDetailsScreen, updateNSFWValue, updateLikedData, updateLikedFeeds, updateLikedComments, setSharePost, setLoginVisible, setShowDeleteConfirmation, setShowLoginModal } = userSlice.actions;
export const userReducer = userSlice.reducer;