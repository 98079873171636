import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDeviceData {
    deviceData: {
        orientation?: string,
        showErrorScreen?: boolean,
        showErrorToast?: boolean,
        errorToastMessage?: string,
        deviceType?: string,
        os?: string,
        errorToastPosition?: string,
    },
}

const initialState: IDeviceData = {
    deviceData: {
        orientation: 'portrait',
        showErrorScreen: false,
        showErrorToast: false,
        errorToastMessage: '',
        deviceType: '',
        os: '',
        errorToastPosition: 'bottom'
    },
};

export const deviceSlice = createSlice({
    name: "device",
    initialState,
    reducers: {
        setOrientation: (state, action: PayloadAction<typeof initialState.deviceData>) => {
            state.deviceData = { ...state.deviceData, ...action.payload };
        },
        setError: (state, action: PayloadAction<typeof initialState.deviceData>) => {
            state.deviceData = { ...state.deviceData, ...action.payload };
        },
        setErrorToast: (state, action: PayloadAction<typeof initialState.deviceData>) => {
            state.deviceData = { ...state.deviceData, ...action.payload };
        },
        setErrorToastMessage: (state, action: PayloadAction<string>) => {
            state.deviceData.errorToastMessage = action.payload
        },
        setDeviceType: (state, action: PayloadAction<{ device: string, os: string }>) => {
            state.deviceData.deviceType = action.payload.device,
                state.deviceData.os = action.payload.os
        },
    },
});

export const { setOrientation, setError, setErrorToast, setErrorToastMessage, setDeviceType } = deviceSlice.actions;
export const deviceReducer = deviceSlice.reducer;