import { CharFeedData } from "@/app/dashboard/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface IFeedData {
    charFeedData: CharFeedData[] | null,
    isNextpageAvailable: boolean,
    pageCursor: string | null,
    deletedFeedId: string | null,
}

const initialState: IFeedData = {
    charFeedData: null,
    isNextpageAvailable: false,
    pageCursor: null,
    deletedFeedId: null,
};

export const feedSlice = createSlice({
    name: "feedData",
    initialState,
    reducers: {
        setFeedData: (state, action: PayloadAction<CharFeedData[]>) => {
            state.charFeedData = action.payload
        },
        updateFeedCursor: (state, action: PayloadAction<{ isNextpageAvailable: boolean, pageCursor: string | null }>) => {
            const { isNextpageAvailable, pageCursor } = action.payload;
            state.isNextpageAvailable = isNextpageAvailable;
            state.pageCursor = pageCursor
        },
        setDeletedFeedId: (state, action: PayloadAction<string | null>) => {
            if (state.charFeedData) {
                state.deletedFeedId = state.deletedFeedId = action.payload;
            }
        }
    },
});

export const { setFeedData, updateFeedCursor, setDeletedFeedId } = feedSlice.actions;
export const feedReducer = feedSlice.reducer;